import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import BeGood from "../../assets/costa-rica.jpg";
import Logo from "../../assets/bird-nest.png";
import { useScreenSize } from "../../hooks.ts/useScreenSize";

export const Footer = ({
  setSelectedPage,
}: {
  setSelectedPage: (page: number) => void;
}) => {
  const isSmallScreen = useScreenSize().width < 600;
  return (
    <FooterContainer>
      <div>
        <ImageTextContainer isSmallerScreen={isSmallScreen}>
          <Typography
            variant={isSmallScreen ? "caption" : "h6"}
            sx={{
              width: "100%",
              position: "absolute",
              margin: "auto",
              textAlign: "center",
              bottom: "20px",
            }}
          >
            If you don't take care of your body, where are you going to live?
          </Typography>
          <StyledImage src={BeGood} alt="take care of yourself" />
        </ImageTextContainer>
        <div>
          <StyledHeader>
            <NameContent>
              <div style={{ marginRight: 60 }}>
                <img height="50" src={Logo} alt="well nest logo" />
              </div>
              <div
                style={{
                  width: "300px",
                  marginRight: 40,
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                {/* <Typography
                  onClick={() => setSelectedPage(6)}
                  sx={{ cursor: "pointer" }}
                  variant="caption"
                >
                  collaborate
                </Typography> */}
                <Typography
                  onClick={() => setSelectedPage(4)}
                  sx={{ cursor: "pointer" }}
                  variant="caption"
                >
                  contact
                </Typography>
                <Typography
                  onClick={() => setSelectedPage(1)}
                  sx={{ cursor: "pointer" }}
                  variant="caption"
                >
                  services
                </Typography>
              </div>
            </NameContent>
          </StyledHeader>
        </div>
      </div>
    </FooterContainer>
  );
};

const NameContent = styled.div`
  padding-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledHeader = styled.div`
  text-align: center;
`;

const StyledImage = styled.img`
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: 0% 20%;
  }}
`;

const FooterContainer = styled.div`
  postition: relative;
  margin-top: 32px;
  padding: 24px;
  border-top: 1px solid black;
`;

const ImageTextContainer = styled.div<{ isSmallerScreen: boolean }>`
  position: relative;
  color: white;
`;
