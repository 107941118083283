import "leaflet/dist/leaflet.css";

import { MapContainer, Marker, TileLayer } from "react-leaflet";
import L from "leaflet";

import NeedleSvg from "../../assets/needle.svg";

const iconPerson = new L.Icon({
  iconUrl: NeedleSvg,
  iconRetinaUrl: NeedleSvg,
  iconAnchor: undefined,
  popupAnchor: undefined,
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: new L.Point(100, 100),
});

const navigateToMap = () =>
  window.open(
    `https://www.google.com/maps/place/ProActive+Physical+Therapy+Centers/@29.5566145,-95.3822573,17z/data=!3m1!4b1!4m6!3m5!1s0x8640933bb0699381:0x53cf7306de78bb93!8m2!3d29.5566099!4d-95.3796824!16s%2Fg%2F1tf5l0cx?entry=ttu`
  );

export const Map = () => {
  return (
    // map indicator and zoom
    <div onClick={navigateToMap}>
      <MapContainer
        center={[29.5566099, -95.3796824]}
        zoom={15}
        scrollWheelZoom={false}
        style={{ height: 300 }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[29.5571599, -95.3800924, 30]} icon={iconPerson}>
          {/* <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup> */}
        </Marker>
      </MapContainer>
    </div>
  );
};
