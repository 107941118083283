import { Typography } from "@mui/material";

export const AcupunctureDetails = () => {
  return (
    <div>
      <div style={{ marginLeft: 24 }}>
        <Typography sx={{ marginBottom: 2 }} variant="h4">
          What is acupuncture?
        </Typography>
        <Typography variant="body1">
          Acupuncture is one of the world’s oldest healing practices. It has
          been practiced in Asian countries for thousands of years. In
          traditional Chinese medicine (TCM), specific points on the body are
          stimulated by very fine sterile stainless steel needles to improve
          health. One of the mechanisms by which acupuncture is- to stimulate
          the central nervous system to release certain hormones into the
          circulatory system. These chemicals in turn, trigger the natural
          healing mechanisms within the body to promote physical and emotional
          well-being.
        </Typography>
      </div>
    </div>
  );
};
