import { Alert, Stack, TextField, TextareaAutosize } from "@mui/material";
import { useAppointment } from "./useAppointment";
import React from "react";

export const Appointment = () => {
  const { error, formState, updateField } = useAppointment();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // @ts-ignore
    updateField({ name, value });
  };

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      <div>
        <Stack direction={"column"} spacing={2}>
          <TextField
            onChange={onChange}
            name="name"
            id="standard-basic"
            label="Name"
            variant="standard"
            value={formState.name}
          />
          <TextField
            onChange={onChange}
            name="email"
            id="standard-basic"
            label="Email"
            variant="standard"
            type="email"
            value={formState.email}
          />
          <TextField
            onChange={onChange}
            name="subject"
            id="standard-basic"
            label="Subject"
            variant="standard"
            value={formState.subject}
          />
          <TextareaAutosize
            name="message"
            id="standard-basic"
            minRows={5}
            maxRows={5}
            value={formState.message}
          />
          <TextField
            onChange={onChange}
            name="phone"
            id="standard-basic"
            label="Standard"
            variant="standard"
            value={formState.phone}
          />
        </Stack>
      </div>
    </>
  );
};
