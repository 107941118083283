import { Paper, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";

import JuanitaFaceImg from "../../assets/testimonials/juanita.jpg";
import LaKiyanaFaceImg from "../../assets/testimonials/lakiyana.jpg";

export const Testimonials = () => {
  return (
    <div>
      <Typography
        variant="h3"
        sx={{ textAlign: "center", marginTop: 2, marginBottom: 3 }}
      >
        Testimonials
      </Typography>

      <Carousel animation="slide" autoPlay stopAutoPlayOnHover>
        <div style={{ padding: 20 }}>
          {testimonials.map((t) => (
            <Testimonial
              name={t.name}
              textSections={t.textSections}
              img={t.img}
              imgStyles={t.imgStyles}
            />
          ))}
        </div>
      </Carousel>
    </div>
  );
};

interface TestimonialProps {
  img?: string;
  imgStyles?: React.CSSProperties;
  name: string;
  textSections: string[];
}

const Testimonial = ({
  img,
  imgStyles,
  name,
  textSections,
}: TestimonialProps) => {
  return (
    <div>
      {img && (
        <div
          style={{
            position: "relative",
            width: 100,
            height: 100,
            backgroundColor: "white",
            border: "2px solid black",
            borderRadius: 50,
            top: "50px",
            left: "calc(50% - 50px)",
            overflow: "hidden",
          }}
        >
          <img
            src={img}
            alt={name}
            style={{ height: "inherit", ...imgStyles }}
          />
        </div>
      )}
      <Paper elevation={4} sx={{ padding: 2, marginBottom: 5 }}>
        <div style={{ marginBottom: img ? 40 : 0 }} />
        {textSections.map((blob) => (
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            {blob}
          </Typography>
        ))}

        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          - {name},
        </Typography>
      </Paper>
    </div>
  );
};

const testimonials: TestimonialProps[] = [
  {
    imgStyles: {
      transform: `translate(12px, 14px) scale(1.33)`,
    },
    img: JuanitaFaceImg,
    name: "Juanita M",
    textSections: [
      `I am 62 years old woman, I’ve had chronic back pain, hips and my legs for a few years now, my massagist
therapy referred me to Marlene Cummings an Acupuncture therapist, I had 2 visits with her and on the
first visit I was amazed how good she is, when I slept on my side I had to sleep with a pillow between my
knees because of constant back pain, and Thank God for Marlene I don’t have to anymore. She
miraculously took the pain I felt when sleeping on my side. I have gone to other Acupuncture Therapists,
but she is the first one that I saw results. I highly recommend her to anyone who suffers from Chronic
pain.`,
      `Thank you!`,
    ],
  },
  {
    img: LaKiyanaFaceImg,
    name: " LaKiyana D",
    textSections: [
      `I met with Dr. Marlene on Feb 21st to discuss acupuncture because it was my first time, and I was having severe back pain. I couldn’t bend over and had a hard time sitting down the pain was present  about 5 days prior. Dr. Marlene explained the procedure and everything that would take place she also walked me through it was very soft spoken and calming and made me feel very comforting and I was confident that this was going to work. The procedure was very comfortable and during the session the pain was even decreasing, and by the time I drove home I felt improvement. By Feb 24th I was back to normal with slight pain just from being sore for so many days. 
      I definitely recommend acupuncture and your experience would be great especially if you have Dr. Marlene provide the procedure.`,
    ],
  },
  {
    name: "LuVonda D",
    textSections: [
      `From the moment I stepped into her serene space, I was greeted with warmth and empathy.`,
      `Marlene’s demeanor is as comforting as her treatment is effective.
    She possesses a rare combination of patience, gentleness, and
    skill that instantly puts you at ease, especially if you're new to
    acupuncture or have reservations about the process. What truly
    sets her apart is her mastery of her craft. Each session feels
    like a personalized journey towards healing, as she carefully
    listens to your concerns and tailors her approach accordingly. Her
    application of the needle is virtually painless, and she takes the
    time to explain the steps of the process, ensuring you feel
    informed and empowered throughout the treatment. Her comprehensive
    understanding of traditional Chinese medicine, coupled with her
    modern scientific knowledge, makes her a truly formidable healer.
    Her academic achievements only underscore her dedication to
    staying at the forefront of her field, ensuring that her patients
    receive nothing but the best care possible.`,
      `If you're hesitant about acupuncture or have yet to find a
    practitioner who truly understands your needs, I urge you to
    schedule an appointment with Dr. Cummings. She's not just a great
    acupuncturist; she's a compassionate healer who enjoys sharing her
    gift and has the power to transform lives.`,
    ],
  },
];
