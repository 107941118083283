import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export const FAQs = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      window.scrollTo(0, 0);
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div>
      <Typography
        variant="h3"
        sx={{ textAlign: "center", marginTop: 2, marginBottom: 3 }}
      >
        Frequently Asked Questions
      </Typography>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <StyledAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography>About Acupuncture</Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography variant="body1">
            Acupuncture is one of the world’s oldest healing practices. It has
            been practiced in Asian countries for thousands of years. In
            traditional Chinese medicine (TCM), specific points on the body are
            stimulated by very fine sterile stainless steel needles to improve
            health. One of the mechanisms by which acupuncture is- to stimulate
            the central nervous system to release certain hormones into the
            circulatory system. These chemicals in turn, trigger the natural
            healing mechanisms within the body to promote physical and emotional
            well-being.
          </Typography>
          <br />
          <Typography variant="body1">
            Acupuncture In The United States of America After the launch of
            acupuncture in ancient China, it spread to other Asian countries.
            Europeans became aware of acupuncture in the 1800s due to their
            colonial contacts with Asia. By the 1950s acupuncture was quite
            popular in Europe. It was not until the 1970s that Americans became
            familiar with the practice. It is not possible to say when
            acupuncture was first practiced in America. Dr. Franklin Bache, the
            great-grandson of Benjamin Franklin was an accomplished surgeon who
            was exposed to acupuncture while on vacation in France around 1825.
            He was intrigued by the results and he performed acupuncture
            experiments on prisoners. In 1826 he published his results in the
            North American Medical and Surgical Journal. He concluded that
            acupuncture was an extremely effective practice for the management
            of pain.
          </Typography>
          <br />
          <Typography variant="body1">
            Acupuncture gained more momentum in the United States in the 1970s.
            In 1971 the US Secretary of State Henry Kissinger traveled to China
            with President Richard Nixon who had a historical state visit with
            Mao Zedong. The travel party included the Pulitzer prize-winning
            journalist with the New York Times, James Reston. In China, Reston
            suffered from a ruptured appendix for which he had emergency
            surgery. The surgery was successful but he suffered from
            post-operative pain. Instead of opioids to treat his pain, he
            received acupuncture. He was very impressed with the effectiveness
            of acupuncture and so when he returned he wrote an article about
            acupuncture in the New York Times. The article helped to popularize
            acupuncture in the United States and the practice became more
            widespread in the 1990s.
          </Typography>
        </StyledAccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <StyledAccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography>What to Expect in a Treatment</Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            For your first consultation your chief complaints, your health
            history as well as your lifestyle are reviewed. If it is your first
            time doing acupuncture and you are a little nervous about do let me
            know so I can talk to you about and help to reduce any anxiety you
            might have. This is done to obtain a good overall picture of your
            health and also to ascertain behaviors that may be contributing
            negatively to your general well-being. After your consultation, a
            treatment personalized to your needs is done.
          </Typography>
        </StyledAccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <StyledAccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <Typography>Do the needles hurt?</Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            The needles are hair-thin and flexible. They are single-use and are
            discarded after each session. You may not be aware that a needle has
            been inserted. Sometimes you may experience a vague, numbness,
            heaviness, achiness, numbness, or a tingling sensation at the site.
            The sensation is transient. With the needles in place, you are left
            to relax for about 30 minutes. Many patients fall asleep during this
            time. How you are likely to feel after treatment varies. Some
            persons may feel very relaxed and rejuvenated after a session.
            Others may feel more energized. It is important to get feedback from
            your experience to assess your progress and plan for further
            sessions.
          </Typography>
        </StyledAccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <StyledAccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <Typography>How many sessions will I need?</Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            The number of sessions will vary from person to person. and it also
            depends on your health goals. The response from acupuncture is
            individualized. Depending on the condition that is being treated,
            some persons notice a difference immediately whilst for others it
            might take a few sessions for them to notice a change.
          </Typography>
        </StyledAccordionDetails>
      </Accordion>
    </div>
  );
};

const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: #e1b689;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  background-color: #fff1e0;
`;
