import {
  Alert,
  Button,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import React, { useEffect } from "react";

import { useContact } from "./useContact";
import { useScreenSize } from "../../hooks.ts/useScreenSize";

export const Contact = ({
  isAppointment,
  onClose,
}: {
  isAppointment?: boolean;
  onClose?: () => void;
}) => {
  const { width } = useScreenSize();
  const { error, formState, isSuccess, onSubmit, updateField } = useContact({
    isAppt: isAppointment || false,
  });

  useEffect(() => {
    if (isSuccess) {
      onClose && onClose();
    }
  }, [isSuccess, onClose]);

  const isSmallScreen = width < 600;
  const headerText = isAppointment ? "Make an Appointment" : "Contact Me";
  const messageLabel = isAppointment ? "Comments / Health Concerns" : "Message";

  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    // @ts-ignore
    updateField({ field: name, value });
  };

  useEffect(() => {
    if (error || isSuccess) {
      window.scrollTo(0, 0);
    }
  }, [error, isSuccess]);

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      {isSuccess && (
        <Alert severity="success">
          Your message has been sent successfully
        </Alert>
      )}
      <Typography
        variant={isSmallScreen ? "h5" : "h4"}
        sx={{ textAlign: "center", paddingTop: 2, marginBottom: 3 }}
      >
        {headerText}
      </Typography>
      <div style={{ maxWidth: 500, padding: 12 }}>
        <Stack direction={"column"} spacing={2}>
          <TextField
            onChange={onChange}
            name="name"
            id="standard-basic"
            label="Name"
            variant="standard"
            value={formState.name}
          />
          <TextField
            onChange={onChange}
            name="phone"
            id="standard-basic"
            label="Phone (optional)"
            variant="standard"
            value={formState.phone}
          />
          <TextField
            onChange={onChange}
            name="email"
            id="standard-basic"
            label="Email"
            variant="standard"
            type="email"
            value={formState.email}
          />
          {!isAppointment && (
            <TextField
              onChange={onChange}
              name="subject"
              id="standard-basic"
              label="Subject"
              variant="standard"
              value={formState.subject}
            />
          )}
          <FormLabel style={{ marginTop: 20 }}>{messageLabel}</FormLabel>
          <textarea
            onChange={onChange}
            style={{ resize: "none", height: 100, width: "inherit" }}
            name="message"
            id="standard-basic"
            value={formState.message}
          />
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button
              variant="contained"
              onClick={onSubmit}
              sx={{ marginTop: 2 }}
            >
              Submit
            </Button>
            {isAppointment && (
              <Button
                variant="outlined"
                onClick={onClose}
                sx={{ marginTop: 2 }}
              >
                Cancel
              </Button>
            )}
          </div>
        </Stack>
      </div>
    </>
  );
};
