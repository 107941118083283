import styled from "@emotion/styled";
import { ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";

import "./App.css";
import "leaflet/dist/leaflet.css";
import { Header } from "./components/";
import { theme } from "./theme/theme";
import { Services } from "./views/Services/Services";
import { FAQs } from "./views/faq/faq";
import { Home } from "./views/home/home";
import { Footer } from "./components/Footer/Footer";
import { Appointment } from "./views/appointment/Appointment";
import { Contact } from "./views/contact/Contact";
import { Testimonials } from "./views/testimonials/Testimonials";

function App() {
  const [selectedPage, setSelectedPage] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedPage]);

  return (
    <ThemeProvider theme={theme}>
      <StyledAppWrapper className="App">
        <Header setSelectedPage={setSelectedPage} />
        <Content>
          {selectedPage === 0 && <Home />}
          {/* {selectedPage === 1 && <AboutMe />} */}
          {selectedPage === 1 && <Services />}
          {selectedPage === 2 && <FAQs />}
          {selectedPage === 3 && <Testimonials />}
          {selectedPage === 4 && <Contact />}
          {selectedPage === 5 && <Appointment />}
          {/* {selectedPage === 6 && <Collaborate />} */}
          <Footer setSelectedPage={setSelectedPage} />
        </Content>
      </StyledAppWrapper>
    </ThemeProvider>
  );
}

export default App;

const Content = styled.div`
  width: 100%;
  margin: auto;
  height: 100%;
`;

const StyledAppWrapper = styled.div`
  max-width: 1200px;
  margin: auto;
`;
