import emailjs from "@emailjs/browser";
import { useState } from "react";

const EMAILJS_KEY = "pJWb669DQ_CW8pNtU";

const defaultAppt = {
  name: "",
  email: "",
  subject: "",
  message: "",
  phone: "",
};

const requiredFields: Partial<keyof typeof defaultAppt>[] = [
  "name",
  "email",
  "message",
];

export const useContact = ({ isAppt }: { isAppt: boolean }) => {
  const [formState, setFormState] = useState(defaultAppt);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const updateField = ({
    field,
    value,
  }: {
    field: keyof typeof defaultAppt;
    value: string;
  }) => {
    setFormState((s) => ({ ...s, [field]: value }));
  };

  const onSubmit = async () => {
    setError("");
    setIsSuccess(false);
    for (const field of requiredFields) {
      if (!formState[field as keyof typeof defaultAppt]) {
        setError("Please include your name, email, subject and message.");
        return;
      }
    }

    try {
      await emailjs.send(
        "default_service",
        "template_pl7yd89",
        { ...formState, form_type: isAppt ? "appointment" : "contact" },
        {
          publicKey: EMAILJS_KEY,
        }
      );

      setIsSuccess(true);
    } catch (e) {
      console.log(e);
      if (e && typeof e === "object" && "text" in e) {
        setError(e.text as string);
      }
      setError("There was a problem submitting the form");
    }
  };
  return {
    error,
    formState,
    updateField,
    isSuccess,
    onSubmit,
  };
};
