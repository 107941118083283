import styled from "@emotion/styled";
import { Tab, Tabs } from "@mui/material";
import { useState } from "react";

import { CuppingDetails } from "./cupping/cupping";
import { FacialRejuvination } from "./facial/facial";

import { AcupunctureDetails } from "./acupuncture/acupuncture";

export const Services = () => {
  const [value, setValue] = useState<string>("acupuncture");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
      <div style={{ margin: "auto" }}>
        <Tabs
          sx={{ margin: "auto", width: 330 }}
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="primary"
          aria-label="services"
        >
          <Tab wrapped value="acupuncture" label="Acupuncture" />
          <Tab wrapped value="cupping" label="Cupping" />
          <Tab wrapped value="facial" label="Facial Rejuvination" />
        </Tabs>
      </div>
      <Content>
        {value === "acupuncture" && <AcupunctureDetails />}
        {value === "cupping" && <CuppingDetails />}
        {value === "facial" && <FacialRejuvination />}
      </Content>
    </div>
  );
};

const Content = styled.div`
  margin-top: 32px;
  width: 100%;
  padding-bottom: 32px;
`;
