import React from "react";
import Carousel from "react-material-ui-carousel";
import { Typography } from "@mui/material";

import LignumVitae from "../../assets/lignum-vitae.jpg";
import Needles from "../../assets/needles.jpg";
import Birds from "../../assets/birds.png";

import styled from "@emotion/styled";
import { AboutMe } from "../about/AboutMe";

import { Map } from "./Map";
import { useScreenSize } from "../../hooks.ts/useScreenSize";

export const Home = () => {
  const { width } = useScreenSize();

  const isSmallScreen = width < 500;
  return (
    <div style={{ position: "relative" }}>
      <div style={{ maxWidth: 650, margin: "auto", position: "relative" }}>
        <WelcomeTextContainer isSmallScreen={isSmallScreen}>
          <Typography variant={isSmallScreen ? "h5" : "h3"}>
            Welcome to Well-Nest Clinic
          </Typography>
          <Typography variant={isSmallScreen ? "h6" : "h4"}>
            acupuncture & Integrative Center
          </Typography>
        </WelcomeTextContainer>
      </div>
      <Carousel height={400}>
        {/* {items.map((item, i) => (
        <Item key={i} item={item} />
      ))} */}
        <img
          style={{
            height: 400,
            width: "100%",
            objectFit: "cover",
            objectPosition: isSmallScreen ? "40% 20%" : "10% 20%",
          }}
          src={LignumVitae}
          alt="Lignum Vitae"
        />
        <img
          src={Needles}
          style={{ height: 400, width: "100%", objectFit: "cover" }}
          alt="Needles"
        />
        <img
          src={Birds}
          style={{
            height: 400,
            width: "100%",
            objectFit: "cover",
            objectPosition: "0% 0%",
          }}
          alt="Birds"
        />
      </Carousel>
      <div>
        <AboutMe />
        <div
          style={{ marginTop: 48, marginBottom: 48, border: "1px solid black" }}
        >
          <Map />
        </div>
      </div>
    </div>
  );
};

const WelcomeTextContainer = styled.div<{ isSmallScreen: boolean }>`
  position: absolute;
  left: 0;
  right: 0;

  margin 0px 12px;
  text-align: center;
  z-index: 2;
  padding: 12px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: saturate(130%) blur(20px);
`;
