import { useState } from "react";

const defaultAppt = {
  name: "",
  email: "",
  subject: "",
  message: "",
  phone: "",
};

const requiredFields: Partial<keyof typeof defaultAppt>[] = [
  "name",
  "email",
  "subject",
  "message",
];

export const useAppointment = () => {
  const [formState, setFormState] = useState(defaultAppt);
  const [error, setError] = useState("");

  const updateField = ({
    field,
    value,
  }: {
    field: keyof typeof defaultAppt;
    value: string;
  }) => {
    setFormState((s) => ({ ...s, [field]: value }));
  };

  const submitForm = () => {
    for (const field of requiredFields) {
      if (!formState[field as keyof typeof defaultAppt]) {
        setError("Please include your name, email, subject and message.");
        return;
      }
    }

    try {
      console.log("sending");
      setError("");
    } catch (e) {
      console.log(e);
      setError("There was a problem submitting the form");
    }
  };
  return {
    error,
    formState,
    submitForm,
    updateField,
  };
};
