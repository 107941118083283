import { Typography } from "@mui/material";

import Marlene from "../../assets/mmy.png";
import { useScreenSize } from "../../hooks.ts/useScreenSize";

export const AboutMe = () => {
  const { width } = useScreenSize();

  const isSmallScreen = width < 500;
  return (
    <div
      style={{ display: "flex", flexWrap: isSmallScreen ? "wrap" : "nowrap" }}
    >
      <div
        style={{
          margin: "auto",
          marginTop: isSmallScreen ? 24 : 0,
          width: "100%",
        }}
      >
        <img
          src={Marlene}
          style={{ maxHeight: 512, width: "inherit" }}
          alt="Dr. Marlene Cummings"
        />
      </div>
      <div style={{ marginTop: 24, marginLeft: 24 }}>
        <Typography variant="h4">Meet Dr. Cummings</Typography>
        <Typography variant="body1" sx={{ margin: "16px 0px" }}>
          I am Marlene Cummings. I am an experienced acupuncturist and herbalist
          who graduated from the American College of Acupuncture and Oriental
          Medicine in 2016. In March 2024 I completed an advanced doctoral
          degree (DAOM) in integrated oriental medicine at Emperor’s College in
          California. The DAOM program has a Physical Medicine module that
          covers orthopedics, sports medicine, acute care, and chronic care.
          Internal Medicine's components include cardiology, reproductive
          medicine, oncology, and immunology.
        </Typography>
        <Typography variant="body1" sx={{ margin: "16px 0px" }}>
          I also practice at MD Anderson Cancer Center. I am originally from
          Jamaica where I practiced Western Medicine as a Family Medicine
          practitioner. In my ideal world, the ultimate health can be best
          achieved in a system that has a balanced blend of the best of Western
          Medicine practices, integrated with evidence-based holistic health
          practices.
        </Typography>
      </div>
    </div>
  );
};
